export const ACC_DELETION_CTA_IDS = {
	delete_account: "PROFILE-SECURITY-DELETE_ACCOUNT",
	proceed: "PROFILE_DELETE-CTA-CONSIDER_PROCEED",
	get_help: "PROFILE_DELETE-LOGO-CONSIDER_GET_HELP",
	get_help_mobweb: "PROFILE_DELETE-CTA-CONSIDER_GET_HELP_MOBWEB",
	reason_back: "PROFILE_DELETE-TEXT-REASON_BACK",
	reason_proceed: "PROFILE_DELETE-CTA-REASON_PROCEED",
	confirm_back: "PROFILE_DELETE-TEXT-CONFIRM_BACK",
	confirm_keep_account: "PROFILE_DELETE-CTA-CONFIRM_KEEP_ACCOUNT",
	confirm_delete: "PROFILE_DELETE-CTA-CONFIRM_DELETE",
	delete_progress_signup: "PROFILE_DELETE-TEXT-DELETION_PROGRESS_SIGNUP",
	delete_progress_email: "PROFILE_DELETE-CTA-DELETION_PROGRESS_EMAIL",
	deletion_progress_WA: "PROFILE_DELETE-CTA-DELETION_PROGRESS_WA",
	logo_exit: "PROFILE_DELETE-LOGO-EXIT",
	text_exit: "PROFILE_DELETE-TEXT-EXIT",
}
