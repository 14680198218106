import { getApiServerBaseUrl } from "@common/utils/env"
import {
	ApiRequest,
	getAPIHeaders,
	getAPIHeadersWithSource,
	getGAClientId,
	getGA4SessionId,
} from "@common/utils/service"
import { SSO_API_SOURCE_FOR_REPO } from "@common/constants"
import { getStrapiAllApiUrl } from "@common/utils/service/apiUrls"
import { getAffiliateCode, getReferralCode } from "@common/utils/auth"

import { AUTH_ACTIONS } from "../constants"

export const generateSmsOTPForUserAPI = async (
	mobileNumber: string,
	authAction?: string,
): Promise<any> => {
	const headers = getAPIHeaders(true, false)
	const body = authAction
		? {
				userMobileNumber: mobileNumber,
				authAction,
			}
		: {
				userMobileNumber: mobileNumber,
			}

	return ApiRequest.post(
		`${getApiServerBaseUrl()}/auth/otp/generate`,
		body,
		headers,
	)
}

export const resendSmsOTPForUserAPI = (
	mobileNumber: string,
	authAction?: string,
) => {
	const headers = getAPIHeaders(true, false)
	const body = authAction
		? {
				userMobileNumber: mobileNumber,
				authAction,
			}
		: {
				userMobileNumber: mobileNumber,
			}
	return ApiRequest.post(
		`${getApiServerBaseUrl()}/auth/otp/resend`,
		body,
		headers,
	)
}

export const submitSmsOTPForUserAPI = (
	mobileNumber: string,
	otp: string,
	authAction?: string,
) => {
	const headers = getAPIHeadersWithSource(true, false)
	const body = authAction
		? {
				userMobileNumber: mobileNumber,
				otp,
				affiliateCode: getAffiliateCode(),
				referralCode: getReferralCode(),
				authAction,
			}
		: {
				userMobileNumber: mobileNumber,
				otp,
				affiliateCode: getAffiliateCode(),
				referralCode: getReferralCode(),
			}
	return ApiRequest.post(
		`${getApiServerBaseUrl()}/auth/otp/verify`,
		body,
		headers,
	)
}

export const generateEmailOtpForUserAPI = (
	tempToken: string,
	userId: number,
	authAction: string,
	userEmail: string | undefined,
) => {
	try {
		const headers = {
			"X-TEMP-TOKEN": tempToken,
			"Content-Type": "application/json",
		}

		return ApiRequest.post(
			`${getApiServerBaseUrl()}/auth/otp/email/generate?userId=${userId}`,

			userEmail ? { userEmail, authAction } : { authAction },
			headers,
		)
	} catch (error) {
		console.error(error)
		return Promise.reject(error)
	}
}

export const resendEmailOtpForUserAPI = (
	tempToken: string,
	userId: number,
	userEmail: string,
	authAction: string,
) => {
	try {
		const headers = {
			"X-TEMP-TOKEN": tempToken,
			"Content-Type": "application/json",
		}

		const body = {
			...(authAction !== AUTH_ACTIONS.forgotPin && { userEmail }),
			authAction,
		}
		return ApiRequest.post(
			`${getApiServerBaseUrl()}/auth/otp/email/resend?userId=${userId}`,
			body,
			headers,
		)
	} catch (error) {
		console.error(error)
		return Promise.reject(error)
	}
}

export const verifyEmailAndSaveUserAPI = (
	tempToken: string,
	userId: number,
	otp: string,
	userName: string,
	emailId: string,
	authAction: string,
) => {
	try {
		const headers = {
			"X-TEMP-TOKEN": tempToken,
			"Content-Type": "application/json",
		}

		const body = {
			otp,
			userName,
			emailId,
			authAction,
			consent: "MITC_CONSENT",
		}

		return ApiRequest.put(
			`${getApiServerBaseUrl()}/auth/otp/email/verify?userId=${userId}`,
			body,
			headers,
		)
	} catch (error) {
		console.error(error)
		return Promise.reject(error)
	}
}

export const verifyUserPinForLoginAPI = (
	tempToken: string,
	pin: string,
	authAction: string,
	userId: number,
) => {
	try {
		const headers = {
			"X-TEMP-TOKEN": tempToken,
			"Content-Type": "application/json",
			source: SSO_API_SOURCE_FOR_REPO,
			"client-id": getGAClientId(),
			"ga-session-id": getGA4SessionId(),
		}

		const body = {
			pin,
			authAction,
		}
		return ApiRequest.post(
			`${getApiServerBaseUrl()}/auth/pin/verify?userId=${userId}`,
			body,
			headers,
		)
	} catch (error) {
		console.error(error)
		return Promise.reject(error)
	}
}

export const resetPinForUserAPI = (
	tempToken: string,
	emailTempToken: string,
	pin: string,
	userId: number,
	authAction: string,
) => {
	try {
		const headers = {
			"X-TEMP-TOKEN": tempToken,
			"X-EMAIL-TEMP-TOKEN": emailTempToken,
			"Content-Type": "application/json",
			source: SSO_API_SOURCE_FOR_REPO,
		}

		const body = {
			pin,
			authAction,
		}
		return ApiRequest.put(
			`${getApiServerBaseUrl()}/auth/pin?userId=${userId}`,
			body,
			headers,
		)
	} catch (error) {
		console.error(error)
		return Promise.reject(error)
	}
}

export const fetchSignUpFormContentAPI = () => {
	const headers = getAPIHeaders(true, false)
	return ApiRequest.get(getStrapiAllApiUrl("fd-s-ignup-modal", "deep"), headers)
}

export const fetchOnboardingSurveysAPI = () => {
	const headers = getAPIHeaders(true, false)
	return ApiRequest.get(
		getStrapiAllApiUrl("onboarding-survey", "deep"),
		headers,
	)
}

export const getSavedUserSurveysAPI = (authToken: string, userId: number) => {
	try {
		const headers = {
			"X-AUTH-TOKEN": authToken,
			"Content-Type": "application/json",
		}

		return ApiRequest.get(
			`${getApiServerBaseUrl()}/user/preferences?userId=${userId}`,
			headers,
		)
	} catch (error) {
		console.error(error)
		return Promise.reject(error)
	}
}

export const saveUserSurveysPreferencesAPI = (
	authToken: string,
	userId: number,
	surveyData: object,
) => {
	try {
		const headers = {
			"X-AUTH-TOKEN": authToken,
			"Content-Type": "application/json",
		}

		return ApiRequest.put(
			`${getApiServerBaseUrl()}/user/preferences/v2?userId=${userId}`,
			{ surveyData },
			headers,
		)
	} catch (error) {
		console.error(error)
		return Promise.reject(error)
	}
}
