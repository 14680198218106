import styled from "styled-components"

export const StyledList = styled.ul`
	padding-left: 24px;
	padding-top: 4px;
`

export const ButtonLoader = styled.div`
	width: 20px;
	height: 20px;
	border: 3px solid #fff;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	margin-left: 0.5rem;

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`
