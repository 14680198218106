import { StyledList } from "../styles";
import { getShuffledArray } from "../utils";
export type Step = (typeof STEPS)[number];
export const STEPS = ["info", "survey", "confirmation", "account-deleted"] as const;
export const ACCOUNT_CLOSURE_GUIDELINES = [{
  title: "Account closure requirements",
  content: <>
				<p>You can&apos;t close your account if:</p>
				<StyledList>
					<li>
						You have confirmed the order in bonds and units have not reached
						your Wint Wealth demat account
					</li>
					<li>You&apos;re holding units in Wint Wealth demat account</li>
					<li>You&apos;ve paid for an FD, but the booking is pending</li>
				</StyledList>
			</>
}, {
  title: "Ensure unit transfer before deletion",
  content: "Please transfer all units from your Wint Wealth demat account before requesting account deletion"
}, {
  title: "Data retention compliance",
  content: "Wint Wealth is obliged to keep the bonds KYC data and investment data for a minimum of 8 years as per SEBI regulations"
}, {
  title: "Download necessary documents",
  content: "Please download all the necessary documents like Investment Summary Report before initiating the account deletion request"
}, {
  title: "Login restriction during deletion",
  content: "You will not be able to login on the platform if your account deletion is in progress"
}, {
  title: "Sign-up with same number",
  content: "You can sign up again with the same phone number, but your new account won't have your previous KYC and investment data"
}, {
  title: "Deletion process duration",
  content: "The account deletion process can take upto 7 days"
}, {
  title: "FD account retention",
  content: "FD account created with the respective bank will not be closed. Please reach out to the bank directly after Wint Wealth account deletion."
}];
export const SURVEY_OPTIONS = getShuffledArray(["I want to open a new account with my current number", "I prefer not to open a demat account", "I didn't know that Wint Wealth opens a demat account", "I faced many issues with my payment", "I no longer use Wint Wealth", "I faced many issues with my KYC", "I experienced delays in my referral payments"]);
export const DELETE_ACC_GUIDELINES_LINK = "https://www.wintwealth.com/account-closure-guidelines/";