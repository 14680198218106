import { getUserId } from "@common/utils/auth"
import { getApiServerBaseUrl } from "@common/utils/env"
import { ApiRequest, getAPIHeaders } from "@common/utils/service"

export const accountDeactivationCheckAPI = () => {
	const headers = getAPIHeaders(true, true)
	return ApiRequest.get(
		`${getApiServerBaseUrl()}/user/${getUserId()}/deactivate/check`,
		headers,
	)
}

export const accountDeactivationAPI = () => {
	const headers = getAPIHeaders(true, true)
	return ApiRequest.put(
		`${getApiServerBaseUrl()}/user/${getUserId()}/deactivate`,
		null,
		headers,
	)
}
