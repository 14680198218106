import { useAppContext } from "@common/context/AppContext";
import { useClickAway } from "@common/hooks/useClickAway";
import { useToastNotification } from "@common/hooks/useToastNotification";
import { minDesktopCss } from "@common/styles/media";
import { BodyMediumText, LabelRegularText } from "@common/styles/typographies";
import { useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { HideScrollBars } from "@common/styles";
import { Checkbox20CheckedIcon, Checkbox20UncheckedIcon, ChevronDown16StrokedIcon, CrossIcon, Warning16FilledIcon } from "wint-icons";
import { SURVEY_OPTIONS } from "../constants";
type DeleteAccountSurveyProps = {
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  otherFeedback: string;
  setOtherFeedback: React.Dispatch<React.SetStateAction<string>>;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
};
const DeleteAccountSurvey: React.FC<DeleteAccountSurveyProps> = ({
  selectedOptions,
  setSelectedOptions,
  otherFeedback,
  setOtherFeedback,
  errorMessage,
  setErrorMessage
}) => {
  const theme = useTheme();
  const {
    isDesktop
  } = useAppContext();
  const {
    showErrorToast
  } = useToastNotification();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useClickAway(() => setIsDropdownOpen(false));
  const isUncheckedError = errorMessage === "Please select at least 1 reason before proceeding.";
  const handleOptionClick = (option: string, isChecked: boolean) => {
    setErrorMessage("");
    if (isChecked) {
      setSelectedOptions(options => options.filter(op => op !== option));
    } else if (selectedOptions.length >= 3) {
      const errror = "Please select a maximum of 3 reasons";
      showErrorToast(errror, isDesktop ? undefined : {
        style: {
          bottom: "120px"
        }
      });
      setErrorMessage(errror);
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    } else {
      setSelectedOptions(options => [...options, option]);
    }
  };
  return <SurveyContainer data-sentry-element="SurveyContainer" data-sentry-component="DeleteAccountSurvey" data-sentry-source-file="DeleteAccountSurvey.tsx">
			<div>
				{isDesktop ? <SurveyOptionDropdownContainer ref={dropdownRef}>
						<SurveyOptionDropdownInput tabIndex={0} $isError={isUncheckedError} onClick={() => {
          setIsDropdownOpen(isOpen => !isOpen);
          setErrorMessage("");
        }}>
							{selectedOptions.length === 0 ? <SurveyContentText style={{
            padding: "2px 0px"
          }}>
									List your reasons
								</SurveyContentText> : <>
									{selectedOptions.map((option, index) => <SurveyOptionPill key={`option-${index}`} onClick={e => e.stopPropagation()}>
											<StyledLabelRegularText>{option}</StyledLabelRegularText>
											<StyledCrossIcon height={12} width={12} onClick={e => {
                e.stopPropagation();
                handleOptionClick(option, true);
              }} />
										</SurveyOptionPill>)}
								</>}
							<ChevronContainer>
								<StyledChevronDownIcon fill={theme.colors.text.tertiary} $isOpen={isDropdownOpen} />
							</ChevronContainer>
						</SurveyOptionDropdownInput>
						<SurveyOptionDropdown $isDropdownOpen={isDropdownOpen}>
							<SurveyOptionList>
								{SURVEY_OPTIONS.map(option => {
              const isChecked = selectedOptions.includes(option);
              return <SurveyOption key={option} $isUncheckedError={isUncheckedError} onClick={() => handleOptionClick(option, isChecked)}>
											<SurveyOptionText className="survey-option-text" $isChecked={isChecked}>
												{option}
											</SurveyOptionText>
											<SurveyOptionCheckbox $isChecked={isChecked}>
												{isChecked ? <Checkbox20CheckedIcon /> : <StyledCheckbox20UncheckedIcon className="survey-option-unchecked-checkbox" />}
											</SurveyOptionCheckbox>
										</SurveyOption>;
            })}
							</SurveyOptionList>
						</SurveyOptionDropdown>
					</SurveyOptionDropdownContainer> : <SurveyOptionList>
						{SURVEY_OPTIONS.map(option => {
          const isChecked = selectedOptions.includes(option);
          return <SurveyOption key={option} $isUncheckedError={isUncheckedError} onClick={() => handleOptionClick(option, isChecked)}>
									<SurveyContentText>{option}</SurveyContentText>
									<SurveyOptionCheckbox $isChecked={isChecked}>
										{isChecked ? <Checkbox20CheckedIcon /> : <StyledCheckbox20UncheckedIcon className="survey-option-unchecked-checkbox" />}
									</SurveyOptionCheckbox>
								</SurveyOption>;
        })}
					</SurveyOptionList>}
				{errorMessage ? <SurveyError>
						<Warning16FilledIcon fill={theme.colors.text.feedback.errorSubtle} />
						{errorMessage}
					</SurveyError> : null}
			</div>
			<OtherFeedbackInputWrapper data-filled={otherFeedback ? "true" : ""} data-sentry-element="OtherFeedbackInputWrapper" data-sentry-source-file="DeleteAccountSurvey.tsx">
				<OtherFeedbackInputLabel className="feedback-input-label" data-sentry-element="OtherFeedbackInputLabel" data-sentry-source-file="DeleteAccountSurvey.tsx">
					Any other feedback?
				</OtherFeedbackInputLabel>
				<OtherFeedbackInput rows={1} value={otherFeedback} onChange={e => {
        e.target.style.height = "20px";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 180)}px`;
        setOtherFeedback(e.target.value);
      }} onFocus={() => setErrorMessage("")} maxLength={1000} data-sentry-element="OtherFeedbackInput" data-sentry-source-file="DeleteAccountSurvey.tsx" />
			</OtherFeedbackInputWrapper>
		</SurveyContainer>;
};
export default DeleteAccountSurvey;
const SurveyContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;

	${minDesktopCss(css`
		gap: 16px;
		min-height: 35vh;
	`)}
`;
const SurveyOptionDropdownContainer = styled.div`
	position: relative;
	overflow: visible;
	z-index: 5;
`;
const SurveyOptionDropdownInput = styled.div<{
  $isError: boolean;
}>`
	outline: none;
	cursor: pointer;
	padding: 16px 12px;
	padding-right: 40px;
	color: ${({
  theme
}) => `${theme.colors.text.primary}`};
	border: ${({
  theme,
  $isError
}) => `1px solid ${$isError ? theme.colors.text.feedback.errorSubtle : theme.colors.stroke.feedback.default}`};
	border-radius: 4px;
	display: flex;
	align-items: center;
	overflow: auto;
	gap: 8px;
	${HideScrollBars}
`;
const SurveyOptionPill = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 2px 12px;
	background-color: ${({
  theme
}) => theme.colors.bg.feedback.disabledEmphasized};
	border-radius: 4px;
	cursor: default;
`;
const StyledCrossIcon = styled(CrossIcon)`
	cursor: pointer;
`;
const SurveyOptionDropdown = styled.div<{
  $isDropdownOpen: boolean;
}>`
	position: absolute;
	top: 54px;
	left: 0px;
	right: 0px;
	padding: 8px;
	border-radius: 4px;
	max-height: 216px;
	overflow: auto;
	display: ${({
  $isDropdownOpen
}) => $isDropdownOpen ? "initial" : "none"};
	border: ${({
  theme
}) => `1px solid ${theme.colors.stroke.feedback.default}`};
	background-color: ${({
  theme
}) => theme.colors.bg.card.default};
`;
const ChevronContainer = styled.div`
	position: absolute;
	right: 1px;
	top: 2px;
	bottom: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	background-color: ${({
  theme
}) => theme.colors.bg.card.default};
`;
const StyledChevronDownIcon = styled(ChevronDown16StrokedIcon)<{
  $isOpen: boolean;
}>`
	flex-shrink: 0;
	cursor: pointer;
	rotate: ${({
  $isOpen
}) => $isOpen ? "180deg" : ""};
	transition: all ease 0.5s;
`;
const SurveyOptionList = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 24px;

	${minDesktopCss(css`
		gap: 8px;
	`)}
`;
const SurveyOption = styled.li<{
  $isUncheckedError: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	.survey-option-unchecked-checkbox {
		fill: ${({
  theme,
  $isUncheckedError
}) => $isUncheckedError ? theme.colors.text.feedback.errorSubtle : theme.colors.text.tertiary};
	}

	${minDesktopCss(css`
		padding: 8px 16px;
		border-radius: 4px;

		&:hover {
			background-color: ${({
  theme
}) => theme.colors.bg.section.emphasized};

			.survey-option-text {
				color: ${({
  theme
}) => theme.colors.text.primary};
			}

			.survey-option-unchecked-checkbox {
				fill: ${({
  theme
}) => theme.colors.text.primary};
			}
		}
	`)};
`;
const StyledLabelRegularText = styled(LabelRegularText)`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
const SurveyOptionText = styled(BodyMediumText)<{
  $isChecked: boolean;
}>`
	color: ${({
  theme,
  $isChecked
}) => $isChecked ? theme.colors.text.primary : theme.colors.text.tertiary};
`;
const SurveyOptionCheckbox = styled.div<{
  $isChecked: boolean;
}>`
	margin-left: 24px;
	transition: transform 0.3s ease;
	transform: ${({
  $isChecked
}) => $isChecked ? "scale(0.95)" : "none"};
`;
const StyledCheckbox20UncheckedIcon = styled(Checkbox20UncheckedIcon)`
	fill: ${({
  theme
}) => theme.colors.text.tertiary};
`;
const OtherFeedbackInputWrapper = styled.div`
	position: relative;
	border: ${({
  theme
}) => `1px solid ${theme.colors.stroke.feedback.default}`};
	border-radius: 4px;
	z-index: 0;
	overflow: hidden;
	padding: 16px 12px;

	&:focus-within,
	&[data-filled]:not([data-filled=""]) {
		border: 1px solid black;
		padding-top: 24px;
		padding-bottom: 8px;

		.feedback-input-label {
			top: 6px;
			left: 12px;
			font-size: 12px;
		}
	}
`;
const OtherFeedbackInputLabel = styled(BodyMediumText)`
	color: ${({
  theme
}) => theme.colors.text.tertiary};
	position: absolute;
	top: 18px;
	left: 12px;
	pointer-events: none;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
`;
const OtherFeedbackInput = styled.textarea`
	color: ${({
  theme
}) => `${theme.colors.text.primary}`};
	resize: none;
	outline: none;
	border: none;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: 0px;
	width: 100%;
	caret-color: ${({
  theme
}) => `${theme.colors.text.textLink}`};
`;
const SurveyError = styled(LabelRegularText)`
	display: none;
	color: ${({
  theme
}) => `${theme.colors.text.feedback.errorSubtle}`};
	align-items: center;
	gap: 6px;
	margin-top: 6px;

	${minDesktopCss(css`
		display: flex;
	`)};
`;
const SurveyContentText = styled(BodyMediumText)`
	color: ${({
  theme
}) => theme.colors.text.tertiary};
`;