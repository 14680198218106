"use client";

import AccordionItem, { Accordion } from "@lib/wint_design_system/molecules/Accordion/AccordionItem";
import Button from "@lib/wint_design_system/molecules/Buttons/Button";
import Image from "next/image";
import BottomSheet from "@lib/wint_design_system/components/BottomSheet/BottomSheet";
import styled, { css, useTheme } from "styled-components";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useAppContext } from "@common/context/AppContext";
import { useToastNotification } from "@common/hooks/useToastNotification";
import { minDesktopCss } from "@common/styles/media";
import { saveUserSurveysPreferencesAPI } from "@features/Authentication/services";
import { getUserAuthToken, getUserId } from "@common/utils/auth";
import { getImagesCdnUrl } from "@common/utils/env";
import { handleEmailUsClick, handleWhatsAppUsClick } from "@common/utils/contact";
import { BodyMediumText, ButtonDefaultText, HeadingSerifMediumText, LabelSemiBoldText } from "@common/styles/typographies";
import { ModalProps } from "@wint_design_system/types/ModalTypes";
import { dropIn } from "@wint_design_system/components/Modal/ModalContent";
import { Tick32FilledIcon, Close16StrokedIcon, HelpChat20StrokedIcon, Info32FilledIcon, Warning32FilledIcon, WhatsappOutlineIcon, ArrowLeft20StrokedIcon, Check20StrokedIcon } from "wint-icons";
import { triggerUserLogout } from "@common/services/auth";
import useLockBodyScroll from "@common/hooks/useLockBodyScroll";
import useKeyPress from "@common/hooks/useKeyPress";
import { FlexSpacer, PersistScrollBars } from "@common/styles";
import { ACCOUNT_CLOSURE_GUIDELINES, STEPS, Step } from "../constants";
import { ButtonLoader } from "../styles";
import { accountDeactivationAPI } from "../services";
import DeleteAccountSurvey from "./DeleteAccountSurvey";
import { ACC_DELETION_CTA_IDS } from "../constants/clickIds";
type StepActionButton = {
  text: string;
  icon?: React.ReactNode;
  onClick: () => void;
  clickId: string;
};
type StepData = {
  heading: {
    icon: React.ReactNode;
    title: string;
    isHelpButtonVisible: boolean;
  };
  content: React.ReactNode;
  actions: {
    primary: StepActionButton;
    secondary?: StepActionButton;
    tertiary?: StepActionButton;
  };
};
type DeleteAccountModalProps = Pick<ModalProps, "show" | "onClose"> & {
  isAccountDeleted?: boolean;
  onSignupWithOtherAccountClick: () => void;
};
const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
  show,
  isAccountDeleted,
  onClose,
  onSignupWithOtherAccountClick
}) => {
  const router = useRouter();
  const {
    isDesktop
  } = useAppContext();
  const {
    showErrorToast
  } = useToastNotification();
  const theme = useTheme();
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const isDeleteApiSuccessful = useRef(false);
  const [currentStep, setCurrentStep] = useState<Step>(isAccountDeleted ? "account-deleted" : "info");
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [otherFeedback, setOtherFeedback] = useState("");
  const [surveyErrorMessage, setSurveyErrorMessage] = useState("");
  const [accountClosingLink, setAccountClosingLink] = useState("");
  const handleModalClose = useCallback(() => {
    setIsConfirmModalOpen(false);
    setSelectedOptions([]);
    setOtherFeedback("");
    onClose();
    if (currentStep !== "account-deleted") {
      setCurrentStep("info");
    } else if (isDeleteApiSuccessful.current) {
      triggerUserLogout(router);
    }
  }, [onClose, router, currentStep]);
  const handleBackClick = () => {
    const currentStepIndex = STEPS.findIndex(step => step === currentStep);
    if (currentStep === "account-deleted" || currentStepIndex < 0) {
      handleModalClose();
    } else {
      setCurrentStep(STEPS[currentStepIndex - 1]);
    }
  };
  const handleSurveySubmit = async () => {
    if (!otherFeedback && selectedOptions.length === 0) {
      const error = "Please select at least 1 reason before proceeding.";
      showErrorToast(error, isDesktop ? {} : {
        style: {
          bottom: "120px"
        }
      });
      setSurveyErrorMessage(error);
      return;
    }
    setIsLoading(true);
    try {
      const surveyData = {
        account_closure_survey: {
          isSkipped: selectedOptions.length === 0,
          choices: otherFeedback ? [...selectedOptions, otherFeedback] : selectedOptions
        }
      };
      const userDetails = {
        userId: getUserId(),
        authToken: getUserAuthToken()
      };
      saveUserSurveysPreferencesAPI(userDetails.authToken!, userDetails.userId!, surveyData);
      if (isDesktop) {
        setCurrentStep("confirmation");
      } else {
        setIsConfirmModalOpen(true);
      }
    } catch (error) {
      showErrorToast(error instanceof Error ? error.message : "Something went wrong!", isDesktop ? {} : {
        style: {
          bottom: "120px"
        }
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteClick = async () => {
    setIsLoading(true);
    try {
      const response = await accountDeactivationAPI();
      isDeleteApiSuccessful.current = true;
      setIsConfirmModalOpen(false);
      setCurrentStep("account-deleted");
      setAccountClosingLink(response.accountClosingLink);
    } catch (error) {
      showErrorToast(error instanceof Error ? error.message : "Something went wrong!", isDesktop ? {} : {
        style: {
          bottom: "120px"
        }
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleSignupWithOtherAccount = () => {
    handleModalClose();
    onSignupWithOtherAccountClick();
  };
  useLockBodyScroll({
    isLocked: show
  });
  useKeyPress("Escape", handleModalClose);
  useEffect(() => {
    if (contentContainerRef.current) {
      setIsContentOverflowing(contentContainerRef.current.clientWidth < contentContainerRef.current.scrollWidth || contentContainerRef.current.clientHeight < contentContainerRef.current.scrollHeight);
    }
  }, [show, currentStep]);
  const getCurrentStepData = (): StepData => {
    switch (currentStep) {
      case "survey":
        return {
          heading: {
            icon: <Info32FilledIcon fill={theme?.colors.text.feedbackInverse.warningSubtle} />,
            title: "Why do you wish to delete your account?",
            isHelpButtonVisible: false
          },
          content: <DeleteAccountSurvey selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} otherFeedback={otherFeedback} setOtherFeedback={setOtherFeedback} errorMessage={surveyErrorMessage} setErrorMessage={setSurveyErrorMessage} />,
          actions: {
            primary: {
              text: "Submit",
              onClick: handleSurveySubmit,
              clickId: ACC_DELETION_CTA_IDS.reason_proceed
            },
            secondary: isDesktop ? undefined : {
              text: "Get help!",
              onClick: handleWhatsAppUsClick,
              clickId: ACC_DELETION_CTA_IDS.get_help_mobweb
            },
            tertiary: {
              text: "Back",
              onClick: handleBackClick,
              clickId: ACC_DELETION_CTA_IDS.reason_back
            }
          }
        };
      case "confirmation":
        return {
          heading: {
            icon: <Warning32FilledIcon fill={theme?.colors.text.feedbackInverse.errorSubtle} />,
            title: "Are you sure about deleting your account permanently?",
            isHelpButtonVisible: false
          },
          content: <ModalContentText>
							Please note once you delete your account, your data like
							investment history, etc. will not be recovered
						</ModalContentText>,
          actions: {
            primary: {
              text: "Delete",
              onClick: handleDeleteClick,
              clickId: ACC_DELETION_CTA_IDS.confirm_delete
            },
            secondary: {
              text: "Keep my account",
              onClick: handleModalClose,
              clickId: ACC_DELETION_CTA_IDS.confirm_keep_account
            },
            tertiary: {
              text: "Back",
              onClick: handleBackClick,
              clickId: ACC_DELETION_CTA_IDS.confirm_back
            }
          }
        };
      case "account-deleted":
        {
          let title = "Account deletion is in progress";
          let body = <span>
						We will take upto 7 days to delete your account. If you require any
						further assistance, please do not hesitate to contact us.
					</span>;
          if (isDesktop) {
            title = "Your account deletion is in progress";
          }
          if (accountClosingLink) {
            title = "Kindly e-sign account closure form";
            body = <>
							<span>
								Kindly e-sign the account closure form sent to your Wint Wealth
								email ID or
							</span>{" "}
							<a href={accountClosingLink} target="_blank" rel="noreferrer">
								use this link
							</a>{" "}
							<span>to proceed with the account deletion process.</span>
						</>;
          }
          return {
            heading: {
              icon: <Tick32FilledIcon fill={theme?.colors.text.feedbackInverse.success} />,
              title: isDesktop ? title : "",
              isHelpButtonVisible: false
            },
            content: isDesktop ? <ModalContentText>{body}</ModalContentText> : <AccountDeletedSectionContainer>
							<Image src={`${getImagesCdnUrl()}/deleteAccountFlow/deleteIllustration.svg`} alt="Account delete illustration" height={312} width={340} />
							<HeadingSerifMediumText>{title}</HeadingSerifMediumText>
							<ModalContentText>{body}</ModalContentText>
						</AccountDeletedSectionContainer>,
            actions: {
              primary: {
                text: "WhatsApp Us",
                icon: isDesktop ? <WhatsappOutlineIcon /> : undefined,
                onClick: handleWhatsAppUsClick,
                clickId: ACC_DELETION_CTA_IDS.deletion_progress_WA
              },
              secondary: isDesktop ? {
                text: "Mail Us",
                onClick: handleEmailUsClick,
                clickId: ACC_DELETION_CTA_IDS.delete_progress_email
              } : {
                text: "Sign up with another account",
                onClick: handleSignupWithOtherAccount,
                clickId: ACC_DELETION_CTA_IDS.delete_progress_signup
              },
              tertiary: {
                text: "Sign up with another account",
                onClick: handleSignupWithOtherAccount,
                clickId: ACC_DELETION_CTA_IDS.delete_progress_signup
              }
            }
          };
        }
      default:
        return {
          heading: {
            icon: <Info32FilledIcon fill={theme?.colors.text.feedbackInverse.warningSubtle} />,
            title: "Account closure guidelines",
            isHelpButtonVisible: true
          },
          content: <Accordion>
							{ACCOUNT_CLOSURE_GUIDELINES.map((guideline, index) => <AccordionItemWrapper key={guideline.title + index.toString()} $isFirstItem={index === 0}>
									<AccordionItem title={guideline.title} content={guideline.content} leadingIcon={isDesktop ? null : <Check20StrokedIcon />} />
								</AccordionItemWrapper>)}
						</Accordion>,
          actions: {
            primary: {
              text: "Proceed",
              onClick: () => setCurrentStep("survey"),
              clickId: ACC_DELETION_CTA_IDS.proceed
            },
            secondary: isDesktop ? undefined : {
              text: "Get help!",
              onClick: handleWhatsAppUsClick,
              clickId: ACC_DELETION_CTA_IDS.get_help_mobweb
            }
          }
        };
    }
  };
  const currentStepData = getCurrentStepData();
  return <>
			<AnimatePresence initial={false} mode="wait" onExitComplete={() => null} data-sentry-element="AnimatePresence" data-sentry-source-file="DeleteAccountModal.tsx">
				{show ? <ModalWrapper show={show} id="modal">
						<Overlay initial={{
          opacity: 0
        }} animate={{
          opacity: 1
        }} exit={{
          opacity: 0
        }} onClick={handleModalClose} />

						<ModalContainer variants={dropIn} initial="hidden" animate="visible" exit="exit">
							<ModalHeading>
								{isDesktop ? <>
										{currentStepData.heading.icon}
										<HeadingSerifMediumText>
											{currentStepData.heading.title}
										</HeadingSerifMediumText>
										<FlexSpacer />
										{currentStepData.heading.isHelpButtonVisible ? <GetHelpButton id={ACC_DELETION_CTA_IDS.get_help} onClick={handleWhatsAppUsClick}>
												<HelpChat20StrokedIcon id={ACC_DELETION_CTA_IDS.get_help} style={{
                  flexShrink: 0
                }} />
												<span id={ACC_DELETION_CTA_IDS.get_help}>
													Get help!
												</span>
											</GetHelpButton> : null}
										<ModalCloseButton onClick={handleModalClose}>
											<Close16StrokedIcon color="white" />
										</ModalCloseButton>
									</> : <>
										<MobileHeaderActions>
											<ArrowLeft20StrokedIcon onClick={currentStep === "account-deleted" ? handleModalClose : handleBackClick} />
											<ModalExitButton type="button" onClick={handleModalClose}>
												<LabelSemiBoldText id={ACC_DELETION_CTA_IDS.text_exit}>
													Exit
												</LabelSemiBoldText>
											</ModalExitButton>
										</MobileHeaderActions>
										<HeadingSerifMediumText>
											{currentStepData.heading.title}
										</HeadingSerifMediumText>
									</>}
							</ModalHeading>

							<ModalContent ref={contentContainerRef} $persistScrollbar={currentStep === "info"}>
								{currentStepData.content}
							</ModalContent>

							<ModalActions $isContentOverflowing={isContentOverflowing}>
								{isDesktop && currentStepData.actions.tertiary ? <>
										<Button clickId={currentStepData.actions?.tertiary?.clickId} text={<ButtonDefaultText $webColor={theme?.colors.text.tertiary} $mobColor={theme?.colors.text.tertiary} id={currentStepData.actions?.tertiary?.clickId}>
													{currentStepData.actions.tertiary.text}
												</ButtonDefaultText>} type="tertiary" size="medium" spacing="center" onClick={currentStepData.actions.tertiary.onClick} disabled={isLoading} desktopStyles={{
                minWidth: "max-content",
                maxWidth: "max-content",
                "&:disabled": {
                  backgroundColor: "inherit"
                }
              }} />
										<FlexSpacer />
									</> : null}

								{currentStepData.actions.secondary ? <Button clickId={currentStepData.actions?.secondary?.clickId} text={<ButtonDefaultText $webColor={theme.colors.text.button.onInverse} $mobColor={theme.colors.text.button.onInverse} id={currentStepData.actions?.secondary?.clickId}>
												{currentStepData.actions.secondary.text}
											</ButtonDefaultText>} type="tertiary" size="medium" spacing="center" onClick={currentStepData.actions.secondary.onClick} disabled={isLoading} desktopStyles={{
              minWidth: "max-content",
              maxWidth: "max-content",
              "&:disabled": {
                backgroundColor: "inherit"
              }
            }} /> : null}
								<Button clickId={currentStepData.actions?.primary?.clickId} text={isLoading ? null : <ButtonDefaultText $webColor={theme.colors.text.button.onPrimary} $mobColor={theme.colors.text.button.onPrimary} id={currentStepData.actions?.primary?.clickId}>
												{currentStepData.actions.primary.text}
											</ButtonDefaultText>} type="primary" size="large" icon={isLoading ? <ButtonLoader /> : currentStepData.actions.primary.icon} spacing={currentStepData.actions.primary.icon ? "space-between" : "center"} onClick={currentStepData.actions.primary.onClick} disabled={isLoading} desktopStyles={{
              maxWidth: "216px",
              minWidth: "fit-content"
            }} />
							</ModalActions>
						</ModalContainer>
					</ModalWrapper> : null}
			</AnimatePresence>
			<BottomSheet show={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)} title={<ConfirmModalTitleContainer>
						<Image src={`${getImagesCdnUrl()}/deleteAccountFlow/warning-mobile.svg`} alt="Red exclamation" height={52} width={52} />
						<HeadingSerifMediumText>
							Are you sure about deleting your account permanently?
						</HeadingSerifMediumText>
					</ConfirmModalTitleContainer>} content={<ConfirmModalContentContainer>
						<ModalContentText>
							Please note once you delete your account, your data like
							investment history, etc. will not be recovered.
						</ModalContentText>
						<ConfirmModalActionsContainer>
							<Button clickId={ACC_DELETION_CTA_IDS.confirm_keep_account} text={<ButtonDefaultText $webColor={theme?.colors.text.button.onInverse} $mobColor={theme?.colors.text.button.onInverse} id={ACC_DELETION_CTA_IDS.confirm_keep_account}>
										Keep my account
									</ButtonDefaultText>} type="secondary" size="medium" spacing="center" onClick={handleModalClose} />
							<Button clickId={ACC_DELETION_CTA_IDS.confirm_delete} text={<ButtonDefaultText $webColor={theme?.colors.text.button.onPrimary} $mobColor={theme?.colors.text.button.onPrimary} id={ACC_DELETION_CTA_IDS.confirm_delete}>
										Delete
									</ButtonDefaultText>} type="primary" size="medium" spacing="center" onClick={handleDeleteClick} />
						</ConfirmModalActionsContainer>
					</ConfirmModalContentContainer>} data-sentry-element="BottomSheet" data-sentry-source-file="DeleteAccountModal.tsx" />
		</>;
};
export default DeleteAccountModal;
const ModalWrapper = styled.div<Pick<ModalProps, "show">>`
	display: ${props => props.show ? "flex" : "none"};
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index: 99999999;
	height: 100vh;
	width: 100vw;

	@supports (height: 100dvh) {
		height: 100dvh;
	}
`;
const Overlay = styled(motion.div)`
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	background-color: ${({
  theme
}) => theme.colors.bg.overlay.backdrop};
	height: 100vh;
	width: 100vw;

	@supports (height: 100dvh) {
		height: 100dvh;
	}

	${minDesktopCss(css`
		display: initial;
	`)}
`;
const ModalContainer = styled(motion.div)`
	position: fixed;
	background-color: ${({
  theme
}) => theme.colors.bg.section.default};
	z-index: 10000;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;

	@supports (height: 100dvh) {
		height: 100dvh;
	}

	${minDesktopCss(css`
		position: relative;
		flex-wrap: nowrap;
		margin: auto;
		height: max-content;
		width: 50vw;
		border-radius: 4px;
		max-height: 50vh;
		max-width: 760px;
		box-shadow: 0px 21px 30.7px 0px rgba(255, 255, 255, 0.02);
	`)}
`;
const ModalHeading = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 0px 24px 16px 24px;

	${minDesktopCss(css`
		flex-direction: row;
		align-items: center;
		padding: 24px;
		border-bottom: 1px solid
			${({
  theme
}) => theme.colors.stroke.divider.default};
		height: 80px;
	`)}
`;
const ModalCloseButton = styled.button`
	all: unset;
	cursor: pointer;
	position: absolute;
	top: 2px;
	right: -24px;
`;
const MobileHeaderActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 0px;
`;
const ModalExitButton = styled.button`
	all: unset;
	cursor: pointer;
`;
const ModalContent = styled.div<{
  $persistScrollbar: boolean;
}>`
	flex-grow: 1;
	padding: 16px 24px;
	overflow-y: scroll;
	overflow-x: hidden;

	${({
  $persistScrollbar
}) => $persistScrollbar ? PersistScrollBars : null}

	${minDesktopCss(css`
		flex-direction: row;
		gap: 32px;
		padding: 24px;
	`)};
`;
const ModalContentText = styled(BodyMediumText)`
	color: ${({
  theme
}) => theme.colors.text.tertiary};

	a {
		color: inherit;
		text-underline-offset: 2px;
	}
`;
const ModalActions = styled.div<{
  $isContentOverflowing: boolean;
}>`
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
	padding: 20px 24px 16px;
	border-top: ${({
  $isContentOverflowing,
  theme
}) => $isContentOverflowing ? `1px solid ${theme.colors.stroke.divider.default}` : "none"};

	${minDesktopCss(css`
		flex-direction: row;
		gap: 32px;
		padding: 24px;
	`)}
`;
const GetHelpButton = styled(ButtonDefaultText)`
	all: unset;
	cursor: pointer;
	display: none;
	color: ${({
  theme
}) => theme.colors.text.tertiary};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	${minDesktopCss(css`
		display: flex;
		align-items: center;
		gap: 6px;
	`)};
`;
const AccordionItemWrapper = styled.div<{
  $isFirstItem: boolean;
}>`
	margin-top: ${({
  $isFirstItem
}) => $isFirstItem ? "0px" : "24px"};
`;
const ConfirmModalTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
const ConfirmModalContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;
const ConfirmModalActionsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;
const AccountDeletedSectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 8px;
`;